import Logo from '../assets/images/cobru-logo.png';
import LogoMutu from '../assets/images/MutuLogo.png';
import LogoEzy from '../assets/images/ezytransfer-logo.png';
import LogoColoca from '../assets/images/LogoColocahorizontal.png';
import LogoPagaHoy from '../assets/images/LogoPagahoyHorizontal.png';
import LogoTrixel from '../assets/images/LogoTrixelHorizontal.png';




const domain = window.location.hostname;

const mutuDomain = 'panel.mutuahorro.com'
const colocaDomain = 'panel.colocapayments.com'
const ezyDomain = 'panel2.ezytransfer.co'
const pagaHoyDomain = 'panel.pagahoy.com'
const trixelDomain = 'panel.trixel.co'


let logoExport = Logo
switch (domain) {
    case mutuDomain:
        logoExport = LogoMutu
        break;
    case ezyDomain:
        logoExport = LogoEzy
        break;
    case colocaDomain:
        logoExport = LogoColoca
        break
    case pagaHoyDomain:
        logoExport = LogoPagaHoy
        break
    case trixelDomain:
        logoExport = LogoTrixel
        break
    default:


        break;
}


export default logoExport