import React, { useContext, useEffect, useState, } from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

// views
import Resumen from '../../../containers/pages/Resumen';
import Cobrus from '../../../containers/pages/Cobrus';
import Retirar from '../../../containers/pages/Retirar';
import Reporte from '../../../containers/pages/Reporte';
import Colaboradores from '../../../containers/pages/Colaboradores';
import Ayuda from '../../../containers/pages/Ayuda';
import Integracion from '../../../containers/pages/Integracion';
import Consultar from '../../../containers/pages/Consultar';
import Recarga from "../../../containers/pages/Recarga"
import Documentos from "../../../containers/pages/Documentos"
import Validacion from "../../../containers/pages/Validacion"
import Clientes from "../../../containers/pages/Clientes"
import ReportesDate from "../../../containers/pages/ReportesDate"
import Debitar from "../../../containers/pages/Debitar"
import BlackList from "../../../containers/pages/BlackList"
import Cambios from "../../../containers/pages/Cambios"


import MutualSidebar from '../../Sidebar/MutualSidebar';
import Nav from '../../NavigationBar';
/* import Enviar from '../../../containers/pages/Enviar';
 */import Operacion from '../../../containers/pages/Operacion';
import Retiros from '../../../containers/pages/Retiros';
import CargarUsuarios from '../../../containers/pages/CargarUsuarios';
import { AuthContext } from '../../../context/AuthContext';
import Alertas from '../../../containers/pages/Alertas';
import Tarifas from "../../../containers/pages/Tarifas"
import ClientesMutu from '../../../containers/pages/mutu/ClientesMutu';
import EnviosMutu from '../../../containers/pages/mutu/EnviosMutu';
import RetirosMutu from '../../../containers/pages/mutu/RetirosMutu';
import RecargasMutu from '../../../containers/pages/mutu/RecargasMutu';
import ReporteMutu from '../../../containers/pages/mutu/ReporteMutu';
import ResumenMutu from '../../../containers/pages/mutu/ResumenMutu';
import variablesDomain from '../../../helpers/variablesDomain';
import RetirosEfecty from '../../../containers/pages/RetirosEfecty';
import NewEnvios from '../../../containers/pages/NewEnvios';
import BrandSibebar from '../../Sidebar/BrandSibebar';
import Cumplimiento from '../../../containers/pages/Cumplimiento';
import CumplimientoPagaHoy from '../../../containers/pages/pagahoy/CumplimientoPagaHoy';


const NoMatch = () => {
    let location = useLocation();
    return (
        <div className="flex md:flex-row flex-wrap p-4">
            <div className="w-full bg-white p-4 text-center ">
                No encontramos nada para <code>{location.pathname}</code>
            </div>
        </div>
    );
}
const RoutesBrands = (props) => {
    let { path, url } = useRouteMatch();
    const { isSuperUser, isOperador } = useContext(AuthContext)
    const user = props.user;
    const pathname = props.pathname

    const cobrus = props.cobrus;
    const totalBalance = props.totalBalance;
    const isCanViewCobrus = props.user.mother_account ? props.user.permission_cobru : 3;

    const pendingTasks = props.pendingTasks;

    const defaultRoutes = [

        { route: '/recargas', component: <Recarga user={user} />, label: 'Recargas', isPrimary: true },
    ]

    const [routes, setroutes] = useState([])

    const getRoutes = () => {
        let xroutes = []
        switch (variablesDomain.brand) {

            case 'PAGAHOY':
                xroutes = [
                    { route: '/cobros', component: <Cobrus cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} canViewCobrus={isCanViewCobrus} />, label: 'Cobros', isPrimary: 'true' },
                    { route: '/recargas', component: <Recarga user={user} />, label: 'Recargas', isPrimary: true },
                    { route: '/retiros', component: <Retiros permittedActions={false} showFilters={false} user={user} />, label: 'Retiros', isPrimary: true },
                    { route: '/envios', component: <NewEnvios task={pendingTasks} totalBalance={totalBalance} isSuperUser={isSuperUser} />, label: 'Envios', isPrimary: true },
                    { route: '/efecty', component: <RetirosEfecty user={user} />, label: 'Efecty', isPrimary: true },
/*                     { route: '/documentos', component: <Documentos user={user} brand={variablesDomain.brand} />, label: 'Documentos', isPrimary: false },
 */                    { route: '/clientes', component: <Clientes cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} canViewCobrus={isCanViewCobrus} />, label: 'Clientes', isPrimary: false },
                    { route: '/cumplimiento', component: <CumplimientoPagaHoy user={user} />, label: 'Cumplimiento', isPrimary: false },
                ]
                break;

            default:
                xroutes = defaultRoutes
                break;
        }
        setroutes(xroutes)
    }
    useEffect(() => {
        getRoutes()
    }, [])


    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/15 bg-trixel-sidebar">
                    <BrandSibebar routes={routes} user={user} pathname={pathname} superUser={props.isSuperUser} />
                    {/* <MutualSidebar user={user} pathname={pathname} superUser={props.isSuperUser} /> */}
                </div>
                <div className="w-full md:w-1/84 bg-trixel-content p-2">
                    <Nav user={user} pathname={pathname} />
                    <Switch>
                        {
                            routes?.length > 0
                                ?

                                <Route exact path="/"  >
                                    <Redirect to={routes[0].route} />
                                </Route>
                                :
                                null
                        }

                        {routes?.map((item) => {
                            return (
                                <Route exact path={item.route}>
                                    {item.component}
                                </Route>
                            )
                        })}

                        <Route path="*" component={() => <NoMatch />} />
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default RoutesBrands;
