import React, { useContext, } from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

// views
import Resumen from '../../containers/pages/Resumen';
import Cobrus from '../../containers/pages/Cobrus';
import Retirar from '../../containers/pages/Retirar';
import Reporte from '../../containers/pages/Reporte';
import Colaboradores from '../../containers/pages/Colaboradores';
import Ayuda from '../../containers/pages/Ayuda';
import Integracion from '../../containers/pages/Integracion';
import Consultar from '../../containers/pages/Consultar';
import Recarga from "../../containers/pages/Recarga"
import Documentos from "../../containers/pages/Documentos"
import Validacion from "../../containers/pages/Validacion"
import Clientes from "../../containers/pages/Clientes"
import ReportesDate from "../../containers/pages/ReportesDate"
import Debitar from "../../containers/pages/Debitar"
import BlackList from "../../containers/pages/BlackList"
import Cumplimiento from "../../containers/pages/Cumplimiento"


import Sidebar from '../Sidebar/Sidebar';
import Nav from '../NavigationBar';
/* import Enviar from '../../containers/pages/Enviar';
 */import Operacion from '../../containers/pages/Operacion';
import Retiros from '../../containers/pages/Retiros';
import CargarUsuarios from '../../containers/pages/CargarUsuarios';
import { AuthContext } from '../../context/AuthContext';
import Alertas from '../../containers/pages/Alertas';
import Tarifas from "../../containers/pages/Tarifas"
import NewEnvios from '../../containers/pages/NewEnvios';
import RetirosEfecty from '../../containers/pages/RetirosEfecty';
import ProductsMovements from '../../containers/pages/ProductsMovements';
/* import DownloadPdfWithdraw from '../../containers/pages/DownloadPdfWithdraw';
 */

const NoMatch = () => {
    let location = useLocation();
    return (
        <div className="flex md:flex-row flex-wrap p-4">
            <div className="w-full bg-white p-4 text-center ">
                No encontramos nada para <code>{location.pathname}</code>
            </div>
        </div>
    );
}

const Admin = (props) => {
    let { path, url } = useRouteMatch();
    const { isSuperUser, isOperador } = useContext(AuthContext)
    const user = props.user;
    const pathname = props.pathname

    const cobrus = props.cobrus;
    const totalBalance = props.totalBalance;
    const isCanViewDashboard = props.user.mother_account ? props.user.permission_dashboard : true;
    const isCanViewCobrus = props.user.mother_account ? props.user.permission_cobru : 3;
    const isCanViewWithdraw = props.user.mother_account ? props.user.permission_withdraw : 3;
    const isCanViewQuery = props.user.mother_account ? props.user.permission_query : true;
    const isCanViewReport = props.user.mother_account ? props.user.permission_reports : true;
    const isCanViewUser = props.user.mother_account ? props.user.permission_users : true;
    const pendingTasks = props.pendingTasks;



    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/15 bg-trixel-sidebar">
                    <Sidebar user={user} pathname={pathname} superUser={props.isSuperUser} />
                </div>
                <div className="w-full md:w-1/84 bg-trixel-content p-2">
                    <Nav user={user} pathname={pathname} />
                    <Switch>
                        <Route exact path="/resumen"  >
                            <Redirect to={"/dashboard"} />
                        </Route>
                        <Route exact path="/"  >
                            <Redirect to={"/dashboard"} />
                        </Route>

                        <Route path="/dashboard" >
                            <Operacion />
                        </Route>

                        
                        <Route path="/validacion"  >
                            <Validacion user={user} />
                        </Route>

                        <Route path="/alertas" component={Alertas} />
                        <Route path="/otros" component={Alertas} />


                        <Route path="/retiros"  >
                            <Retiros user={user} />
                        </Route>
                        <Route path="/efecty"  >
                            <RetirosEfecty user={user} />
                        </Route>
                        <Route path="/tarifas"  >
                            <Tarifas user={user} />
                        </Route>
                        <Route path="/blacklist"  >
                            <BlackList user={user} />
                        </Route>
                        <Route path="/reportesdate"  >
                            <ReportesDate user={user} />
                        </Route>
                        <Route path="/debitar"  >
                            <Debitar user={user} />
                        </Route>
                        <Route path="/cargar"  >
                            <CargarUsuarios user={user} />
                        </Route>

                        {/* <Route exact path="/" component={() => <Resumen cobrus={cobrus} totalBalance={totalBalance} canViewDashboard={isCanViewDashboard} />} /> */}

                        <Route path="/resumen" component >
                            <Resumen cobrus={cobrus} totalBalance={totalBalance} canViewDashboard={isCanViewDashboard} />
                        </Route>

                        <Route path="/cobrus"  >
                            <Cobrus cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} canViewCobrus={isCanViewCobrus} />
                        </Route>

                        <Route path="/enviar"  >
                            <NewEnvios task={pendingTasks} totalBalance={totalBalance} isSuperUser={isSuperUser} />
                        </Route>
                        <Route path="/clientes"  >
                            <Clientes cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} canViewCobrus={isCanViewCobrus} />
                        </Route>

                        <Route path="/retirar"  >
                            <Retirar totalBalance={totalBalance} canViewWithdraw={isCanViewWithdraw} />
                        </Route>
                        <Route path="/consultar"  >
                            <Consultar canViewQuery={isCanViewQuery} />
                        </Route>

                        <Route path="/reportes" component={() => <Reporte canViewReport={isCanViewReport} />} />
                        <Route path="/colaboradores" component={() => <Colaboradores canViewUser={isCanViewUser} />} />
                        <Route path="/ayuda" component={Ayuda} />
                        <Route path="/integracion" component={() => <Integracion user={user} />} />
                        <Route path="/recargas"  >
                            <Recarga user={user} />
                        </Route>
                        <Route path="/productos"  >
                            <ProductsMovements user={user} />
                        </Route>
                        <Route path="/cumplimiento"  >
                            <Cumplimiento user={user} />
                        </Route>

                        <Route path="/documentos"  >
                            <Documentos user={user} />
                        </Route>
                       {/*  <Route path="/detail_withdraw_pdf/:id" >
                            <DownloadPdfWithdraw/>
                        </Route>  */}

                        <Route path="*" component={() => <NoMatch />} />
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default Admin;
